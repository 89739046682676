<style>

.bg {
    background: #2f2f2f !important;
}

</style>

<script>

</script>

<h4>Credits</h4>
<ul class="collection">
  <li class="collection-item bg">
    <a href="https://opengameart.org/content/32x32-fantasy-portrait-set">
      https://opengameart.org/content/32x32-fantasy-portrait-set
    </a>
    by CobraLad
  </li>
</ul>
